import { useEffect, useState } from 'react'

import { logOutService, getUserLoggedInService } from '../services/loginServices'
import useGoBack from '../hooks/useGoBack'
import { UserModel } from '../models/UserModel'
import CopyRight from '../components/common/CopyRight'
import DefaultItems from '../components/Items/DefaultItems'



const ProfilePage : React.FC = ()=> {

  const goBack                       = useGoBack()
  const [user, setUser]              = useState<UserModel>()

  useEffect(()=> {

    const _user = getUserLoggedInService()

    if( _user )
      setUser( _user )

  }, [])

  const signOut = ()=> {

    logOutService()
    window.location.replace('/')
    window.location.reload()

  }



  return (
    <div>

      <div className=" min-h-screen m-2 p-0 md:px-10  xl:px-40 xl:mx-28 2xl:mx-60">


        <div className="flex mx-1 my-2 ">

            <button onClick={ goBack }>
              <i className="fas fa-arrow-left fa-2x text-yellow-500 mr-1"></i>
            </button>
            <h3 className="mx-2 text-3xl md:text-4xl  text-gray-200 font-bold flex-1 place-self-center">
              Profile
            </h3>

        </div>


        <div className="mt-10 flex justify-between content-center items-center mx-3 sm:mx-10 ">
            <h3 className="text-xl text-gray-100 font-black "> User&#128241;</h3>
        </div>


        <div className="bg-gray-800 rounded-xl py-8 sm:px-6 mt-2 lg:m-10 lg:mt-3  flex flex-col  shadow-xl overflow-hidden p-2">

          <h3 className="text-xl md:text-2xl text-gray-200 font-bold text-center">
            {user?.name}
          </h3>

          <h3 className=" text-base md:text-xl text-gray-300 font-bold text-center ">
            {user?.email}
          </h3>

          <h3 className="text-base text-gray-400 font-bold text-center">
          {user?.providerId}
          </h3>

        </div>
     

        <div className="mt-5 lex justify-between content-center items-center mx-3 sm:mx-10 sm:mb-3">
            <h3 className="text-xl text-gray-100 font-black "> Default Items<span> &#129302;  </span> </h3>
        </div>


        <DefaultItems />


        <div className="flex justify-center mt-20 mb-10">

          <button
            onClick ={  signOut }
            className="bg-red-900 text-white px-4 py-3 rounded-lg hover:bg-red-700">
            <span> <i className="fas fa-sign-out-alt mx-1"></i> </span>
              Sign Out
          </button>

        </div>

      </div>

      <CopyRight />

    </div>
  )
}

export default ProfilePage
