

type IProps = {
  title    : string
  text?    : string
}


const NoData : React.FC<IProps> = ( { text, title } )=> {

  return (

    <div data-testid="nodata">
        <h4 className="text-center text-blue-100 text-2xl font-black"> { title } </h4>
        <p className="text-center text-blue-100 text-2xl "> { text } </p>
        <pre className="text-4xl text-gray-100 font-black text-center">&#128554;</pre>
    </div>

  )
}


export default NoData
