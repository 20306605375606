
import BtnLoginWithGoogle from '../components/auth/BtnLoginWithGoggle'
import CopyRight from '../components/common/CopyRight'

const LandingPage : React.FC = () => {


  return (
    <div className=" bg-gray-900  ">

      <nav className="mt-2 mx-1 flex justify-between p-2 sm:p-5" >

        <a className="text-yellow-500 mx-1 sm:mx-2 " href="/">
          <i className="fas fa-feather-alt text-2xl"></i>
          <h1 className="inline text-2xl md:text-3xl  font-extrabold sm:mx-1"> WriteDown </h1>
        </a>

        <div className="">
          <BtnLoginWithGoogle />
        </div>


      </nav>


      <div className="min-h-screen flex flex-col text-center p-6 md:px-40 lg:px-48 xl:mx-24 lg:-mt-10">

        <p className="text-center mt-5 ">
          <span className="text-8xl md:text-9xl" > &#128220; </span>
        </p>

        <h3 className="text-5xl text-gray-300 font-extrabold my-6 lg:text-7xl md:my-4 lg:my-6">
          One place for all your shop <span className="text-yellow-500"> lists </span>
        </h3>

        <p className="text-gray-400 text-3xl mt-3 lg:mt-6" >
          Create, organize, edit, complete and also delete your shop lists.
        </p>
        <p className="text-gray-400 text-3xl mt-3 lg:mt-6">
          WriteDown aims for simplicity, allowing you to keep records of all your lists
        </p>

      </div>



      <div className="min-h-screen flex flex-col justify-center items-center p-6 md:px-40 lg:px-48 xl:mx-24 lg:-mt-10">

        <p className="text-center -mt-5">
          <span className="text-8xl md:text-9xl" > &#128683; </span>
        </p>

        <h3 className="text-5xl text-gray-300 font-extrabold my-6 lg:text-7xl md:my-4 lg:my-6">
          <span className="text-yellow-500">Ads</span>-free
        </h3>

        <p className="text-gray-400 text-3xl mt-3 lg:mt-6">
          free of annoying ads, just focus on your lists and nothing more
        </p>

      </div>

      <div className="text-center my-16">
        <BtnLoginWithGoogle text="Continue with Google" />
      </div>


      <div className="text-center mt-16 mb-10 mx-4">
        <CopyRight />
      </div>



</div>
  )

}

export default LandingPage
