import firebase from 'firebase/app'
import 'firebase/auth'


let URL_GRAPHQL = "http://localhost:3002/graphql"

if (process.env.NODE_ENV === 'production') {
  URL_GRAPHQL = "https://write-down-be-6u5gd.ondigitalocean.app/graphql"
} else {
  URL_GRAPHQL = "http://192.168.1.6:3002/graphql"
  // URL_GRAPHQL = "http://localhost:3002/graphql"
}

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = firebase.initializeApp( {
  apiKey: "AIzaSyA_jj_7OR8fpQSevmPdZMODjMonm4fvKLM",
  authDomain: "write-down-87fef.firebaseapp.com",
  projectId: "write-down-87fef",
  storageBucket: "write-down-87fef.appspot.com",
  messagingSenderId: "1088054775053",
  appId: "1:1088054775053:web:5497b655124031a2c4f444",
  measurementId: "G-DYL97S9G9F"
});


const providerGoogle = new firebase.auth.GoogleAuthProvider();




export { URL_GRAPHQL, firebaseConfig, providerGoogle }
