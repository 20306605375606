
import { useMutation } from '@apollo/client'

import Loading from '../common/Loading'
import ShowError from '../common/ShowError'

import {  DELETE_SHARE_WITH } from '../../graphql/mutations'
import { SharedWith } from '../../models/ListModel'
import { deleteshareListWithUserService } from '../../services/apolloServices'


type IProps = {
  listId       : string
  sharedWith   : SharedWith[]
}

const ListSharedWith: React.FC<IProps> = ({ listId, sharedWith }) => {
  

  const [deleteShareWith , { loading , error}] = useMutation(DELETE_SHARE_WITH)

  const _removeShareWith = (userId : string)=> {

    if(!userId)
      return

    deleteShareWith({
      variables: { shareListInput: { listId, toUserId: userId } }, update: (cache) => {
        deleteshareListWithUserService( cache, listId, userId )
    } } )
  }


  if (error)
    return (<div className="flex items-center justify-center"> <ShowError error={error} /> </div>    )

  return (
    <div >

      <div className="flex justify-center mt-4 "> <Loading loading={ loading } /> </div>
      
      <div className="flex flex-row flex-wrap "> 

          {  sharedWith?.map((user)=> {
              return (

                <div className="bg-gray-700 rounded-lg p-1 px-3 text-gray-200 font-semibold m-1 " key={ user.userId }>
                  <span> { user.name } </span>
                  <button
                    disabled={loading}
                    onClick={() => _removeShareWith(user.userId)}>
                    <i className="fas fa-minus-circle text-red-700 fa-lg mx-1"></i>
                  </button>
                </div>

              )

            })
        }
        
      </div>

    </div>
  )

}

export default ListSharedWith
