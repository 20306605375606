import { useQuery } from '@apollo/client';

import Loading from '../common/Loading';
import ShowError from '../common/ShowError';
import Item from './Item';
import NoData from '../common/NoData';

import { ItemsDefaultResponse } from '../../models/Response'
import { GET_DEFAULT_ITEMS } from '../../graphql/querys'

const DeafultItems : React.FC = ()=> {

  const { loading, error, data }     = useQuery<ItemsDefaultResponse>(GET_DEFAULT_ITEMS );


  if (loading === true)
    return (<div className=" flex items-center justify-center mt-6 "> <Loading loading={ loading } /> </div>    )

  if (error)
    return (<div className="flex items-center justify-center"> <ShowError error={error} /> </div>    )

  return (
    <div className="max-h-screen rounded-xl overflow-y-auto p-2">
      <div className={"bg-gray-800 rounded-xl p-2 my-2 mb-4 sm:m-10 md:mt-4  "+(data?.itemsDefault?.length === 0 ? "" : "hidden" )}>

      <div className="my-10">
        <NoData title={"No items"}  />
      </div>

      </div>

      <div className={"bg-gray-800 rounded-xl p-2 my-2 mb-4 sm:m-10 md:mt-4  "+(data?.itemsDefault?.length === 0 ? "hidden" : "" )}>

        <ul className="grid grid-cols-1 divide-y divide-gray-700 mx-1 text-white place-content-end gap-4">

          {
            data?.itemsDefault.map(( item )=> <Item item={ item } edit={ true } key={ item.id } isDefaultItem={ true } / > )
          }

        </ul>

      </div>
    </div>
  )
}

export default DeafultItems
