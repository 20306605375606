import { getUserLoggedInService } from './loginServices'

export const showFormNewItemService = () : boolean => {

  const state = localStorage.getItem("showFormNewItem") || ""

  if ( !state )
    return true
  else if (state === "true")
    return true
  else
    return false

}


export const showSharedListService = (): boolean => {

  const state = localStorage.getItem("showSharedListService") || ""

  if ( !state )
    return true
  else if (state === "true")
    return true
  else
    return false

}


export const isUserOwner = ( userId : string )=> {

  if(!userId)
    return false

  const userLoggedIn = getUserLoggedInService()

  if(userLoggedIn?.userId === userId)
    return true
  else
    return false


}
