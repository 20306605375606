import { ListModel } from '../../models/ListModel'
import { calculatePercentAndGetWidth } from '../../services/utils'

type IProps = {
  list : ListModel
}

const ListStats :  React.FC<IProps> = ( { list } )=> {

  let total = 0
  let itemCompleted = 0

 
  list?.items?.forEach((item) => {
   total = total + item.price

   if( item.done )
    itemCompleted++
 })

  return (

    <div className={" rounded-xl p-1 mb-4 mt-2 sm:mx-10 md:m-16 md:mt-2 lg:mx-28 lg:my-16 "+(list?.items?.length === 0 ? "hidden" : "" )}>

      <ul className="grid grid-cols-2 p-4 justify-center">

        <li className="my-4 text-center col-span-2">
          <div className="w-full h-2 bg-gray-500 rounded">
            <div className={"h-2 bg-pink-900 rounded   " + calculatePercentAndGetWidth(list?.items?.length || 0, itemCompleted)} >
            </div>
          </div>
        </li>

        <li className=" flex flex-col md:flex-row items-center content-center justify-center">
          <h3 className="text-lg text-gray-400  font-semibold ">Items:</h3>
          <pre className="text-lg text-gray-300 font-bold"> {list?.items?.length}</pre>
        </li>

        <li className=" flex flex-col md:flex-row items-center content-center justify-center">
          <h3 className="text-lg text-gray-400  font-semibold ">Total:</h3>
          <p className="text-lg text-gray-300 font-bold">
            <span className="text-gray-400 italic text-sm" > {`${list?.currency}`} </span>
            {`${total.toFixed(2)}`}
          </p>
        </li>


      </ul>

    </div>

  )
}


export default ListStats
