import React from 'react'
import { useQuery } from '@apollo/client';
import { TEST_QUERY } from '../graphql/querys';
import Loading from '../components/common/Loading';

interface TestData {
  test: string;
}


const TestPage: React.FC = () => {
  
  const { loading, error, data } = useQuery<TestData>(TEST_QUERY);
  
  console.log(loading, error, data)

  if (loading === true) return <Loading loading={ loading } />;
  // if (error) return <p>Error :(</p>;

  return (
    <div className="text-white">

      
      
      <h3> { data?.test } </h3>
      
    </div>
  )
}

export default TestPage
