import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import AddNewList from '../components/List/AddNewList';
import ListItem from '../components/List/ListItem';
import Loading from '../components/common/Loading';
import ShowError from '../components/common/ShowError';
import { GET_LISTS, GET_LISTS_SHARED } from '../graphql/querys';
import { ListsResponse } from '../models/Response';
import NoData from '../components/common/NoData';
import { showSharedListService } from '../services/preferenceServices';

export default function ListsPage() {

  const [showForm, setshowForm]             = useState(false)
  const [showSharedList, setShowSharedList] = useState(true)
  const { loading, error, data }            = useQuery<ListsResponse>(GET_LISTS);
  const { data: listShared }                = useQuery<ListsResponse>(GET_LISTS_SHARED);
  const hasSharedlists                      = ( (listShared?.lists.length || 0) > 0 ? true : false)
  const hasLists                            = (data?.lists?.length === 0 ? false : true)
  
  useEffect(()=> {
    const _showSharedList = showSharedListService()
    setShowSharedList( _showSharedList )

  }, [])

  useEffect(()=> {
    localStorage.setItem("showSharedListService", String(showSharedList) )

  }, [showSharedList])

  if (error)
    return (<div className="flex items-center justify-center"> <ShowError error={error} /> </div>    )


  return (
    <div>

      
      <nav className="mt-2  flex justify-between sm:mx-10 " >
        <Link to="/" className="text-yellow-500 mx-2 ">
          <i className="fas fa-feather-alt text-3xl"></i>
        </Link>

        <Link to="/profile"  className="text-yellow-500 mx-2 ">
          <i className="fas fa-user text-3xl"></i>
        </Link>
      </nav>


      <div className="m-2 p-0 md:px-10  xl:px-40 xl:mx-28 2xl:mx-60">
        <div className="flex justify-between content-center items-center  mt-10  sm:mx-10 md:mt-4 ">
            <h3 className="text-4xl text-gray-100 font-black "> Lists  </h3>

            <button onClick={ ()=> setshowForm( !showForm ) }>
              <i className={"fas fa-plus text-3xl text-yellow-500 transition duration-300 ease-in-out  " + (showForm ? "transform rotate-45" : "") }></i>
            </button>
        </div>


        <div className={"bg-gray-800 rounded-xl p-2 sm:p-4 sm:mx-10  " + (showForm ? "" : "hidden")} >

          <AddNewList/>

        </div>

        <div className={" " + ( hasSharedlists ? "" : "hidden")}>

          <div className="flex justify-between content-center items-center px-2 mt-6 sm:mx-10 ">
            <h3 className="text-2xl text-gray-100 font-black   "> Shared lists &#128279; </h3>
            
            <button onClick={ ()=> setShowSharedList( !showSharedList ) } className="mx-2 p-1">
              { showSharedList ? (
                <i className="fas fa-caret-down fa-lg text-gray-500 "></i> 

              ) : (
                <i className="fas fa-caret-right fa-lg text-gray-500 "></i>
              ) }
              </button>
          </div>

          
          <div className={"bg-gray-800 rounded-xl p-2 mb-4 sm:m-10 mt-3 sm:mt-2  " + (hasSharedlists && showSharedList ? "" : "hidden")}>
            <ul className="grid grid-cols-1 divide-y divide-gray-700 mx-1 text-white place-content-end gap-4">
              {
                listShared?.lists.map((_list, idx) => <ListItem key={idx} listModel={_list} />)
              }
            </ul>    
          </div>
        
        </div>

        <div className="flex items-center justify-center  "> <Loading loading={ loading } /> </div>
     
        
        <h3 className={"text-2xl text-gray-100 px-2 mt-5 font-black sm:ml-10 " + (hasSharedlists ? "" : "hidden")} > My Lists </h3>
        
        <div className={"bg-gray-800 rounded-xl p-2 mt-6 mb-4  sm:mt-4 m-4 lg:m-8  "+( hasLists ? "hidden" : "" )}>

          <div className="my-4">
            <NoData title="No lists" />
          </div>

        </div>

        <div className={" bg-gray-800 rounded-xl p-2 mb-4 sm:m-10 mt-2 sm:mt-2  "+( hasLists ? "" : "hidden" )}>

          <ul className="grid grid-cols-1 divide-y divide-gray-700 mx-1 text-white place-content-end gap-4">
            {
              data?.lists.map( (_list, idx) => <ListItem key={idx} listModel={ _list } /> )
            }
          </ul>

        </div>

      </div>
    </div>

  )
}
