import { useState } from 'react'
import { useMutation } from '@apollo/client'

import Loading from '../common/Loading'
import ShowError from '../common/ShowError'

import { GET_LIST_ById, GET_DEFAULT_ITEMS } from '../../graphql/querys'
import { UPDATE_ITEM } from '../../graphql/mutations'
import { ListItemModel, QuantityModel, ListModel } from '../../models/ListModel'
import { ListResponse, ItemsDefaultResponse } from '../../models/Response'

type IProps = {
  item: ListItemModel
  setShowDetails : Function
  isDefaultItem? : boolean
}

const ItemDetail : React.FC<IProps> = ( { item, setShowDetails, isDefaultItem } ) => {

  const [itemDetail, setitemDetail]       = useState<ListItemModel>(item)
  const [newQuantity, setNewQuantity]     = useState<QuantityModel | undefined >( item.quantity || null )

  const [updateItem, { loading, error }]  = useMutation(UPDATE_ITEM)



  if ( error )
    return (<div className="flex items-center justify-center">
        <ShowError error={ error } /> </div>
  )


const _updateItem = ()=> {

  if ( !itemDetail.name )
      return

  setitemDetail( {...itemDetail, quantity : { value :  newQuantity?.value, unit : newQuantity?.unit }  } )
  const {id,  done, listId, price, priority, name, description, userId } = itemDetail
  const quantity : QuantityModel = { unit : newQuantity?.unit, value : newQuantity?.value || 0  }
  const _data = { id,done, listId, price,  priority, description, name, userId, quantity, isDefaultItem }

  updateItem({ variables: { ItemInput: _data } , update: (cache) => {
    try {

      if ( isDefaultItem ) {
        let _data = cache.readQuery<ItemsDefaultResponse>({ query: GET_DEFAULT_ITEMS  });

        if( !_data || !_data?.itemsDefault)
          return


        let newItems : ListItemModel[] = _data!.itemsDefault!.map((item)=> {
          if(item.id === id)
            return {...itemDetail,  quantity : { value :  newQuantity?.value, unit : newQuantity?.unit }  }
            else
              return item
          })

          setShowDetails( false )

          cache.writeQuery( { query: GET_DEFAULT_ITEMS, data: { 'itemsDefault': newItems! } } );
      } else {
        let _data = cache.readQuery<ListResponse>({ query: GET_LIST_ById,  variables: { listId }  });

        if ( !_data || !_data?.list)
          return

          if (!_data.list.items)
            return

        let newItems : ListItemModel[] = _data!.list!.items?.map((item)=> {
          if(item.id === id)
            return {...itemDetail,  quantity : { value :  newQuantity?.value, unit : newQuantity?.unit }  }
            else
              return item
          })
          let  newList : ListModel = { ..._data!.list, items : newItems }
          setShowDetails( false )

          cache.writeQuery({ query: GET_LIST_ById, data: { 'list': newList! } });
      }


      }
      catch(err){
        console.log( err )
      }
  }})

}


  return (

    <div className="flex justify-center">
        <div className="flex flex-col w-full md:w-2/3 p-1 sm:p-4 md:p-5">

        <div className="flex bg-gray-600 items-center rounded-lg pl-1 sm:pl-3">    
          {/* <span className="text-2xl mr-1"> &#128717;</span> */}

          <input
            type="text"
            placeholder="Item"
            autoComplete="off"
            value={itemDetail.name}
            onChange={(e) => setitemDetail({ ...itemDetail, name: e.target.value })}
            className="w-full bg-gray-600 flex-grow rounded-md p-2 py-4 text-white placeholder-gray-400 focus:outline-none" />
            
        </div>

            
        <div className="grid grid-cols-2 gap-2 ">

              
          <div className="flex divide-x-2 divide-gray-400 divide-opacity-20">

                
            <div className="flex bg-gray-600 items-center rounded-l-lg mt-1 pl-1 sm:pl-3">
                  
              {/* <span className="text-2xl mr-1"> &#128290;</span> */}
              <input
                type="number"
                placeholder="Qty"
                autoComplete="off"
                value={(newQuantity?.value || "")}
                onChange={(e) => { setNewQuantity({ ...newQuantity!, value: parseFloat(e.target.value) }) }}
                className="w-full bg-gray-600 p-1 sm:p-2 py-4 text-white placeholder-gray-400 focus:outline-none" />
                
            
            </div>

                
            <div className="flex bg-gray-600 items-center  rounded-r-lg mt-1 pl-1 ">
                  
              <input
                type="text"
                placeholder="Unt"
                autoComplete="off"
                value={newQuantity?.unit || ""}
                onChange={(e) => { setNewQuantity({ ...newQuantity!, unit: e.target.value }) }}  
                className="w-full bg-gray-600 p-1 sm:p-2 py-4 text-white placeholder-gray-400 focus:outline-none" />    
            </div>
              
          </div>

              
          <div className="flex bg-gray-600 items-center rounded-lg mt-1">
          
            {/* <span className="text-2xl mr-1"> &#128176;</span> */}

            <input
              type="number"
              placeholder="0.00"
              autoComplete="off"
              value={(itemDetail.price || "")}
              onChange={(e) => setitemDetail({ ...itemDetail, price: parseFloat(e.target.value) })}    
              className="w-full bg-gray-600 flex-grow rounded-md p-2 py-4 text-white placeholder-gray-400 focus:outline-none" />
              
          </div>
    
        </div>


           
        <div className="flex bg-gray-600 items-center rounded-lg mt-1 pl-1 sm:pl-3">
              
          {/* <span className="text-2xl mr-1"> &#128172;</span> */}
          
          <input
            type="text"
            name="txtName"
            id="txtName"
            placeholder="Note"
            value={itemDetail.description || ""}
            onChange={(e) => setitemDetail({ ...itemDetail, description: e.target.value })}
            className="w-full bg-gray-600 flex-grow rounded-md p-2 py-4 text-white placeholder-gray-400 focus:outline-none" />
            
        </div>
            
        <button
          disabled={loading}          
          onClick={_updateItem}
          className="bg-pink-800 text-white p-3.5 font-semibold rounded-lg mt-1 ">
            Update Item
           
        </button>

            
        <div className="flex items-center justify-center my-2">
          <Loading loading={loading} />    
        </div>

        
      </div>
    </div>
  )
}


export default ItemDetail
