const getDateFromUnix = (unixTime : string) : Date => {

  const date = new Date( parseInt(unixTime ));

  return date
}

const calculatePercentAndGetWidth = (total : number, done : number) : string => {

  const percent = done / total * 100

  if (percent === 0 )
    return   "w-0.5"
  else if ( percent <= 8.33)
    return   "w-1/12"
  else if ( percent <= 16.66)
    return   "w-2/12"
  else if ( percent <= 25)
    return   "w-3/12"
  else if ( percent <= 33.33)
    return   "w-4/12"
  else if ( percent <= 41.66)
    return   "w-5/12"
  else if ( percent <= 50)
    return   "w-6/12"
  else if ( percent <= 58.33)
    return   "w-7/12"
  else if ( percent <= 66.66)
    return   "w-8/12"
  else if ( percent <= 75)
    return   "w-9/12"
  else if ( percent <= 83.33)
    return   "w-10/12"
  else if ( percent <= 98)
    return   "w-11/12"
  else
    return   "w-full"


}

export  { getDateFromUnix, calculatePercentAndGetWidth }
