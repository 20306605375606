import {  useHistory } from 'react-router-dom'


const ListNotFound : React.FC = ()=> {

  let history                        =  useHistory();

  const goBack = () => {
    history.push('/')
  }

  return (
    <div>

      <div className="flex mt-2 ">
        <button onClick={ goBack }>
          <i className="fas fa-arrow-left fa-2x text-yellow-500 mr-1"></i>
        </button>
      </div>

        <div className={"bg-gray-800 rounded-xl p-2 mt-8 mb-4  m-8 lg:mx-48  "}>

          <div className="my-10">
            <p className="text-center text-blue-100 text-2xl font-black"> No Found </p>
            <h3 className="text-4xl text-gray-100 font-black text-center">  &#128554; </h3>
          </div>

      </div>

    </div>
  )
}

export default ListNotFound
