import { useEffect, useState } from "react"
import { ListModel } from "../../models/ListModel"
import { showFormNewItemService } from "../../services/preferenceServices"
import NoData from "../common/NoData"
import AddNewItem from "../Items/AddNewItem"
import Item from "../Items/Item"

type IProps = {
  list : ListModel
}


const ListBody: React.FC<IProps> = ( { list } ) => {

  const listId                  = list.id
  const [editItems, setEdit]    = useState(false)
  const [showForm, setshowForm] = useState(false)
  const hasItems                = list.items?.length === 0 ? false : true
  const items                   = list?.items || []
  const currency                = list?.currency || "$"
  
  
  useEffect(()=> {
    const _showForm = showFormNewItemService()
    setshowForm( _showForm )

  }, [])

  useEffect(()=> {
    localStorage.setItem("showFormNewItem", String(showForm) )

  }, [showForm])


  
  const _editList = ( edit: boolean ) => {
    setEdit(edit)
    
    if (edit) {
      setshowForm( false )
    }

  }
  
  return (
    <div >
            
      <div className="sticky top-0 bg-gray-900">

        <div className=" bg-gray-900 flex justify-between content-center items-center px-3 sm:px-10 mt-4 py-2 ">

          <div className="flex ">
            <button
              onClick={() => _editList(!editItems)}>
              <i className={"fas fa-ellipsis-h text-2xl text-yellow-500 mr-2  transition duration-500 ease-in-out  " + (editItems ? "transform rotate-90" : "")}></i>
            </button>
              
            <h3 className="text-3xl text-gray-100 font-black "> Items </h3>

          </div>


          <button
            onClick={() => setshowForm(!showForm)}>
            <i className={"fas fa-plus fa-2x text-yellow-500 transition duration-300 ease-in-out " + (showForm ? "transform rotate-45" : "")}></i>
          </button>

        </div>

        <div className={"pb-1 " + (showForm ? "" : "hidden")}>
          <AddNewItem listId={ listId }/>
        </div>
              
      </div>

      <div className={"bg-gray-800 rounded-xl py-6 my-6  sm:mt-2 m-4 lg:m-8  "+( hasItems ? "hidden" : "" )}>
          <NoData title="No items" />
      </div>


      <div className={"bg-gray-800 rounded-xl p-2 my-2 mb-4 sm:m-10 md:mt-4  "+( hasItems ? "" : "hidden" )}>

        <ul className="grid grid-cols-1 divide-y divide-gray-700 mx-1 text-white place-content-end gap-4">
          {
            items.map((item) => <Item item={item} edit={editItems} currency={ currency } key={item.id} />) 
          }          
        </ul>

      </div>


    </div>
  )

}

export default ListBody