import { ListModel } from "../../models/ListModel"
import { isUserOwner } from "../../services/preferenceServices"
import ListSharedWith from "./ListSharedWith"
import ListStats from "./ListStats"

type IProps = {
  list : ListModel
}



const ListFooter: React.FC<IProps> = ({ list }) => {
  
  const listId                = list.id
  const isOwner               = isUserOwner(list.user?.userId || "")
  const hasSharedList         = list?.sharedWith.length === 0 ? false : true
  const hasItems              = list?.items?.length === 0 ? false : true


  
  return (
    
    <div>
      
      <div className={" " + (hasItems ? "" : "hidden")}>
        <ListStats list={list} />
      </div>


      
      <div className={"mb-12 " + ( ( hasSharedList && isOwner )  ? "" : "hidden") }>

        <h3 className="text-xl text-gray-100 font-black mx-3 sm:mx-10  "> Shared with &#128279; </h3>
        <div className={"sm:m-10 sm:mt-1  "}>
          <ListSharedWith listId={ listId } sharedWith={ list?.sharedWith || [] }/>
        </div>

      </div>
      

    </div>


  )

}

export default ListFooter