
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import ListPage from '../pages/ListPage';
import ListsPage from '../pages/ListsPage';
import TestPage from '../pages/TestPage';
import ProfilePage from '../pages/ProfilePage';


const AppRouters : React.FC = ()=> {


  return (
    <div className="min-h-screen bg-gray-900  p-2 sm:p-5 ">
      <Router>

        <Switch>

          <Route path="/test">
            <TestPage/>
          </Route>

          <Route path="/profile">
            <ProfilePage />
          </Route>

          <Route path="/list/:listId">
            <ListPage/>
          </Route>

          <Route path="/">
            <ListsPage/>
          </Route>
        </Switch>

      </Router>
    </div>
  )
}

export default AppRouters
