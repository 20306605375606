
import { useMutation } from '@apollo/client'
// import { useHistory } from 'react-router'


import { signInWithGoogle } from '../../services/loginServices'
import { LOGIN  } from '../../graphql/mutations'
import { LoginResponse } from '../../models/Response'
import Loading from '../common/Loading'
import ShowError from '../common/ShowError'

type IProps = {
  text?    : string
}

const BtnLoginWithGoogle : React.FC<IProps> = ( { text = "Sign In" } ) => {

  // let history                                 = useHistory()
  const [ loginMutation, { loading, error } ] = useMutation<LoginResponse>(LOGIN )

  const signIn = async ()=> {

    try {

      const userToken = await signInWithGoogle()

      
      await loginMutation({
        variables: { loginInput: userToken }, update: (_, { data: _newData }) => {

        if (!_newData?.registerAndVerifyLogin )
          throw new Error('Something went wrong')

        const { token, newListId }  = _newData?.registerAndVerifyLogin!

        localStorage.setItem("token", token)

        if (userToken?.isNewUser === true && newListId) {
          const url = `/list/${newListId}`
          // history.push( url )
          window.location.replace(url)
        } else {
          window.location.replace('/')
        }


      }} )
    } catch (err) {

    }


  }

if( loading ) return <Loading loading={ loading } />

if ( error )
  return (<div className="flex items-center justify-center">
      <ShowError error={ error } /> </div>
)


  return (
    <button
    onClick={ signIn }
      className="bg-pink-800  text-white font-semibold rounded-lg px-6 py-2">
       <i className="fab fa-google"></i>  { text }
    </button>
  )
}

export default BtnLoginWithGoogle
