
import { Link } from 'react-router-dom'
import dayjs  from 'dayjs'
import relativeTime  from 'dayjs/plugin/relativeTime'


import { ListModel } from '../../models/ListModel'
import { getDateFromUnix } from '../../services/utils'
import { isUserOwner } from '../../services/preferenceServices'
import { UserModel } from '../../models/UserModel'
import { useState } from 'react'


dayjs.extend(relativeTime)

type IProps = {
  listModel : ListModel
}

const ListItem: React.FC<IProps> = ({ listModel }) => {

  const [ user ]          = useState < UserModel | null>(listModel?.user || null)
  const isOwner           = isUserOwner( user?.userId  || "" )
  const hasSharedList     = listModel?.sharedWith?.length === 0 ? false : true
  
  return (
    <li>
      
      <Link to={`/list/${listModel.id}`} className="flex items-center ">

        {hasSharedList
          ? <i className="fas fa-link w-5 text-gray-400"></i>
          :  <span className="  w-5   ">   </span> 
        }
                
        <div className="flex-grow overflow-hidden ">
          
          <h3 className="text-xl lg:text-2xl font-bold">
            {listModel.description}
          </h3>
          <p className=" text-gray-400 truncate">  
            {dayjs(getDateFromUnix(listModel.updatedAt)).fromNow()}
          </p>
          <h4 className={"text-gray-300 truncate font-semibold " + ( isOwner ? "hidden" : "" ) }>
            <span className="text-gray-400"> By: </span>  { user?.name }
          </h4>

        </div>

        <i className="fas fa-angle-right fa-lg text-gray-500 w-6"></i>
        
      </Link>

    </li>
  )
}



export default ListItem
