import { useMutation  } from '@apollo/client'

import Loading from '../common/Loading'
import { DELETE_LIST, LEAVE_LIST } from '../../graphql/mutations'
import { removeListFromCacheService } from '../../services/apolloServices'
import { isUserOwner } from '../../services/preferenceServices'

type IProps = {
  listId  : string
  userId  : string
}

const RemoveList : React.FC<IProps> = ( { listId, userId } ) => {

  const isOwner                   = isUserOwner( userId )
  const [deleteList, { loading }] = useMutation(DELETE_LIST, { variables: { listId }, update: cache => removeListFromCacheService(cache, listId) });
  const [leaveList, { loading : loading2 }] = useMutation(LEAVE_LIST, { variables: { listId }, update: cache => removeListFromCacheService(cache, listId) });
  



if( loading || loading2 )
  return <Loading loading={ loading  || loading2} />

  return (
    <div className="flex justify-center">

      { isOwner
        ?
        (
          <button
            onClick ={  ()=> deleteList() }
            className="bg-red-900 text-white px-4 py-2.5 rounded-lg hover:bg-red-600">
            <span> <i className="fas fa-trash-alt mx-1"></i> </span>
              Delete List
          </button>
        ) :
        (
          <button
            onClick ={  ()=> leaveList() }
            className="bg-red-900 text-white px-4 py-2.5 rounded-lg hover:bg-red-600">
            <span> <i className="fas fa-trash-alt mx-1"></i> </span>
              Leave List
          </button>

        )

      }


    </div>
  )

}


export default RemoveList
