import { useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'

import Loading from '../common/Loading'
import ShowError from '../common/ShowError'

import { SEARCH_USER } from '../../graphql/querys'
import { SHARE_LIST } from '../../graphql/mutations'
import { SearchUserResponse } from '../../models/Response'
import { isUserOwner } from '../../services/preferenceServices'
import { shareListWithUserService } from '../../services/apolloServices'

type IProps = {
  listId       : string
}

const FormShareList : React.FC<IProps> = ( { listId } )=> {


  const [userSearch, setUserSearch]                = useState("")
  const [searchUser, { loading,error,data }]       = useLazyQuery<SearchUserResponse>( SEARCH_USER, { variables : { searchKey : userSearch } } )
  const [shareList , { loading : loading2 }]       = useMutation(SHARE_LIST)

const _shareList =  ()=> {

  if(!data?.searchUser.userId)
    return
  
    
  if ( isUserOwner(data?.searchUser.userId) )
    return

  shareList({
    variables: { shareListInput: { listId, toUserId: data?.searchUser.userId || "" } },
    update: (cache) => {
      shareListWithUserService(cache, listId, data.searchUser)
      setUserSearch("")
    }
  })
  

}
  


  if (error)
    return (<div className="flex items-center justify-center"> <ShowError error={error} /> </div>    )



  return (
    <div className="w-full lg:w-2/3 text-center">
      <input
        type="email"
        placeholder="User email"
        autoComplete="off"
        className="bg-gray-600 p-1 py-2 text-white placeholder-gray-400 focus:outline-none  rounded-l-md w-2/3  "
        onChange={ (e)=> setUserSearch( e.target.value ) }
        value={ userSearch }
        />

      <button
        onClick={ ()=> searchUser() }
        disabled={ loading }
        className="bg-pink-800 text-gray-200 p-1 py-2 font-semibold rounded-r-md -ml-1 px-3">
        Search
      </button>



      <div className="flex items-center justify-center mt-4 "> <Loading loading={ loading || loading2 } /> </div>

      <ul className=" divide-y divide-gray-700 text-white place-content-end gap-4 max-h-96 overflow-auto text-left p-2">

        <li className={"my-4 p-1 flex items-center " + (!data?.searchUser ? "hidden" : "" )  } >

          <div className="flex-1">
            <h3 className="text-lg font-bold">
              {data?.searchUser?.name}
            </h3>
            <p className="text-gray-400">
              {data?.searchUser?.email}
            </p>
          </div>

          <button
            onClick={ _shareList }
            className=" p-1 rounded m-1 ml-2 font-bold ">
             <i className="fas fa-share text-yellow-500 fa-lg"></i>
          </button>

        </li>


      </ul>

    </div>
  )

}

export default FormShareList
