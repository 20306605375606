import { gql } from "@apollo/client";

const ADD_LIST = gql`
  mutation addList($description: String!) {
    list :addList(description: $description) {
      id
      description
      createdAt
      userId
      description
      items {
        id
      }
      completed
    }
  }
`;

const ADD_ITEM = gql`
  mutation addItem($input: ItemInput) {
    addItem(input: $input) {
      id
      userId
      name
      description
      done
      price
      quantity {
        value
        unit
      }
      priority
      createdAt
      updatedAt
    }
  }
`;

const DELETE_ITEM = gql`
  mutation deleteItem($input: DeleteItemInput) {
    deleteItemFromList(input: $input)
  }
`;

const DELETE_LIST = gql`
  mutation deleteList($listId: String) {
    deleteList(listId: $listId)
  }
`;

const LEAVE_LIST = gql`
  mutation leaveList($listId: String) {
    leaveList(listId: $listId)
  }
`;

const TOGGLE_ITEM = gql`
  mutation toggleItem($ToogleItemInput: ToogleItemInput) {
    toggleItemFromList(input: $ToogleItemInput)
  }
`;

const UPDATE_ITEM = gql`
  mutation updateItem($ItemInput: ItemInput) {
    updateItemFromList(input: $ItemInput)
  }
`;

const UPDATE_LIST_NAME = gql`
  mutation updateListName($listId: String, $description: String) {
    updateListName(listId: $listId, description: $description)
  }
`;


const UPDATE_LIST_CURRENCY = gql`
  mutation updateListCurrency($listId: String, $currency: String) {
    updateListCurrency(listId : $listId, currency : $currency)
  }
`;

const CLONE_LIST = gql`
  mutation cloneListName($listId: String, $description : String){
    list :cloneList(listId :$listId,  description : $description ) {
      id
      description
      createdAt
      userId
      description
      items {
        id
      }
      completed
    }
  }
`;

const SHARE_LIST = gql`
  mutation shareList($shareListInput: shareListInput) {
    shareList(input: $shareListInput)
  }
`;

const DELETE_SHARE_WITH = gql`
  mutation deleteShareWith($shareListInput: shareListInput) {
    deleteShareWith(input: $shareListInput)
  }
`;

const LOGIN = gql`
  mutation registerAndVerifyLogin($loginInput: loginInput) {
    registerAndVerifyLogin(input: $loginInput) {
      token
      newListId
    }
  }
`;

export {
  ADD_LIST,
  ADD_ITEM,
  CLONE_LIST,
  DELETE_ITEM,
  DELETE_LIST,
  DELETE_SHARE_WITH,
  LEAVE_LIST,
  LOGIN,
  SHARE_LIST,
  UPDATE_ITEM,
  UPDATE_LIST_CURRENCY,
  UPDATE_LIST_NAME,
  TOGGLE_ITEM,
};
