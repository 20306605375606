import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import Loading from '../components/common/Loading';
import ShowError from '../components/common/ShowError';
import ListNotFound from '../components/List/ListNotFound';

import { ListResponse } from '../models/Response';
import { GET_LIST_ById } from '../graphql/querys'
import ListHeader from '../components/List/ListHeader';
import ListBody from '../components/List/ListBody';
import ListFooter from '../components/List/ListFooter';

type TParams = { listId: string };

const ListPage : React.FC = ()=> {

  let { listId }                   = useParams<TParams>();
  const { loading, error, data }   = useQuery<ListResponse>(GET_LIST_ById, { variables: { listId } });

  if (error)
    return (<div className="flex items-center justify-center"> <ShowError error={error} /> </div>    )

  if (!loading && (!data || !data.list) )
    return ( <ListNotFound /> )


  return (

    <div className="m-2 p-0 md:px-10 lg:px-20 lg:mx-16  xl:px-40 xl:mx-28 2xl:mx-60 ">

      <div className="flex items-center justify-center  "> <Loading loading={ loading } /> </div> 
      
      {data?.list && <ListHeader list={data.list} />}
       
      { data?.list && <ListBody list={ data.list } /> }
      
      { data?.list && <ListFooter list={ data.list } /> }



    </div>

  )
          
}


export default  ListPage
