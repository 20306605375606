import { useMutation } from "@apollo/client"
import { useState } from "react"
import { UPDATE_LIST_CURRENCY, UPDATE_LIST_NAME } from "../../graphql/mutations"
import { ListModel } from "../../models/ListModel"
import { updateLisNameService, updateListCurrencyService } from "../../services/apolloServices"
import Loading from "../common/Loading"
import ShowError from "../common/ShowError"

type IProps = {
  list   : ListModel  
}


const ListSettings: React.FC<IProps> = ( { list: { description, id : listId, currency: currentCurrency } } ) => {
  
  const [currency, setCurrency]                                   = useState( currentCurrency )
  const [updateCurrency, { loading, error }]                      = useMutation(UPDATE_LIST_CURRENCY)
  const [newTitle, setNewTitle]                                   = useState( description )
  const [updateListName, { loading: loading2, error : error2  }]  = useMutation( UPDATE_LIST_NAME )

  
  const _updateCurrency = () => {
    
    if ( !currency )
      return
    
      updateCurrency( { variables : { listId, currency }, update : (cache)=> {
        
        updateListCurrencyService( cache, listId, currency )
  
      } })

  }


  const _updateListName = ()=> {

    if ( !newTitle || newTitle.trim().length === 0)
        return

    updateListName( { variables : { listId, description : newTitle }, update : (cache)=> {
      
      updateLisNameService(cache, listId, newTitle)

    } })
  }


  if (loading || loading2)
    return (<div className="flex items-center justify-center  "> <Loading loading={ loading || loading2 } /> </div>    )

    
  if (error)
    return (<div className="flex items-center justify-center"> <ShowError error={error} /> </div>)

  if (error2)
      return (<div className="flex items-center justify-center"> <ShowError error={error2} /> </div>)
  
  

  return (
    <div className="w-full flex flex-col  items-center ">


      <div className={"mx-1 w-full md:w-2/3 my-5 group  "}>
        
        <h3 className="text-lg text-gray-100 font-extrabold "> List Name:</h3>
          
        <input
          type="text"
          placeholder="List name"
          autoComplete="off"
          className="bg-gray-600 p-2 text-white placeholder-gray-400 focus:outline-none w-2/3 rounded-l-md "
          onChange={ (e)=> setNewTitle( e.target.value ) }
          value={ newTitle }
          />


        <button
          onClick={ _updateListName }
          className="bg-pink-800 text-gray-200  p-2 font-semibold w-1/3 rounded-r-md -ml-1">
          <i className="fas fa-pencil-alt "></i>
          <span className="hidden md:inline" >  Update </span>
        </button>
        
      </div>

      <div className={"mx-1 w-full md:w-2/3 mb-8 group "}>
        
        <h3 className="text-lg text-gray-100 font-extrabold "> Currency:</h3>
          
        <input  
          type="text"
          placeholder="$"
          autoComplete="off"
          className="bg-gray-600 p-2 text-white placeholder-gray-400 focus:outline-none w-2/3 rounded-l-md  "
          onChange={(e) => setCurrency(e.target.value)}
          value={ currency }
            />

        <button
          onClick={ _updateCurrency }
            className="bg-pink-900 text-gray-200 p-2 font-semibold w-1/3 rounded-r-md -ml-1 group-hover:bg-pink-800">
            <i className="fas fa-pencil-alt "></i>
            <span className="hidden md:inline" >  Update </span>
          </button>
        
      </div>
      
    </div>
  )

}

export default ListSettings