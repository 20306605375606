import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ListModel } from "../../models/ListModel";
import { isUserOwner } from "../../services/preferenceServices";
import CloneList from "./CloneList";
import FormShareList from "./FormShareList";
import ListSettings from "./ListSettings";
import RemoveList from "./RemoveList";

type IProps = {
  list : ListModel
}


const ListHeader: React.FC<IProps> = ( { list } ) => {

  let history = useHistory();
  const { id : listId, userId  }                    = list
  const [showFormSharedList, setshowFormSharedList] = useState(false)
  const [showFormCloneList, setshowFormCloneList]   = useState(false)
  const [showSettings, setShowSettings]             = useState(false)
  const name                                        = list.user?.name 
  const isOwner                                     = isUserOwner( userId )

  const showForm = ( formName : string ) => {
    
    if (formName === "share") {
      setshowFormSharedList( !showFormSharedList )
      setShowSettings(false)
      setshowFormCloneList(false)
    } else if (formName === "clone") {
      setshowFormCloneList( !showFormCloneList )
      setshowFormSharedList(false)
      setShowSettings(false)
    } else if (formName === "settings") {
      setShowSettings( !showSettings )
      setshowFormCloneList(false)
      setshowFormSharedList(false)
    }
    else {
      setshowFormSharedList(false)
      setShowSettings(false)
      setshowFormCloneList(false)
    }

  }
  

  const goBack = () => history.push('/')

  return (
    <div className="bg-gray-900 ">
        
      <div className="flex mx-1 my-4 sm:mx-10 ">

        <button onClick={ goBack }>
          <i className="fas fa-arrow-left text-2xl text-yellow-500 mr-1"></i>
        </button>

        
        <div className="flex-1 ">

          <h3 className={"mx-2 text-2xl md:text-3xl  text-gray-200 font-semibold flex-1 place-self-center " }>
            { list.description }
          </h3>
          <h4 className={"text-gray-300 truncate font-semibold mx-2 " + ( isOwner ? "hidden" : "" ) }>
            <span className="text-gray-400"> By: </span>  { name }
          </h4>

        </div>


        <button
          disabled={!isOwner}
          className={"text-yellow-500  " + (isOwner ? "" : "opacity-50")}
          onClick={() => showForm( 'clone' ) } >
          <i className="fas fa-copy text-2xl mx-4  "></i>
        </button>



        <button
          disabled={!isOwner}
          className={"text-yellow-500  " + (isOwner ? "" : "opacity-50")}
          onClick={() => showForm( 'share' ) } >
        <i className="fas fa-share text-2xl  "></i>
        </button>



        <button  
          className={"text-yellow-500   "}
          onClick={() => showForm( 'settings' ) } >
          <i className="fas fa-cog text-2xl  ml-4 "></i>  
        </button>
        

      </div>

  
      <div className={"mt-6 " + (showFormSharedList ? "" : "hidden")}>

        <h3 className="text-xl text-gray-100 font-black mx-3 sm:mx-10  "> Shared List &#128279; </h3>

        <div className={"bg-gray-800 rounded-xl p-6 px-0  mb-4 sm:m-10 md:mt-4 flex justify-center "}>
          <FormShareList listId={listId} />
        </div>
  
      </div>

        
      <div className={"mt-6 " + (showFormCloneList ? "" : "hidden")}>

        <h3 className="text-xl text-gray-100 font-black mx-3 sm:mx-10  "> Clone List &#128279; </h3>

        <div className={"bg-gray-800 rounded-xl p-6 px-0  mb-4 sm:m-10 md:mt-4 flex justify-center "}>
        <CloneList listId={ listId } setshowFormCloneList={ setshowFormCloneList } />
        </div>
      
      </div>


        
      <div className={"mt-6 " + (showSettings ? "" : "hidden")}>

        <h3 className="text-xl text-gray-100 font-black mx-3 sm:mx-10  "> Settings </h3>

        <div className={" bg-gray-800 rounded-xl  px-4 py-4 mb-10 sm:mx-10 flex flex-col justify-center "}>
          {isOwner && <ListSettings list={list} />}
          <RemoveList listId={listId} userId={userId} />
        </div>

      </div>


    </div>
  )

}


export default ListHeader