import { useMutation } from "@apollo/client"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { CLONE_LIST } from "../../graphql/mutations"
import { AddListResponse } from "../../models/Response"
import { addLisService } from "../../services/apolloServices"
import Loading from "../common/Loading"
import ShowError from "../common/ShowError"


type IProps = {
  listId                : string
  setshowFormCloneList  : Function
}

const CloneList: React.FC<IProps> = ({ listId, setshowFormCloneList }) => {
  

  let history                           = useHistory();
  const [description, setDescription]   = useState("")
  
  const [cloneList, { loading, error }] = useMutation<AddListResponse>(CLONE_LIST, {
    variables: { listId, description }, update: (cache, { data }) => {
    
      if (!data?.list)
          return

      addLisService( cache, data!.list )

      const id  = data?.list.id || ""
      
      if (!id)
          return

      setshowFormCloneList( false )
      
      const url = `/list/${ id }`
      history.push( url );

  } } )
  



  if (loading )
    return (<div className="flex items-center justify-center  "> <Loading loading={ loading } /> </div>)



  if (error)
  return (<div className="flex items-center justify-center"> <ShowError error={error} /> </div>    )


  return (
    <div className="w-full lg:w-2/3 py-3 text-center">
      <input
        onChange={(e) => setDescription(e.target.value)}
        value={ description }
        type="email"
        placeholder="New List name"
        autoComplete="off"
        className="bg-gray-600 p-1 py-2 text-white placeholder-gray-400 focus:outline-none  rounded-l-md w-2/3  "
        />

      <button
        disabled={!description}
        onClick={ ()=> cloneList() }
        className="bg-pink-800 text-gray-200 p-1 py-2 font-semibold rounded-r-md -ml-1 px-3">
        Clone
      </button>

    </div>
  )
}

export default CloneList