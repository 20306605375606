import React from 'react'
import "./Loading.css";

type IProps = {
  loading : boolean
}

const Loading: React.FC<IProps> = ({ loading }) => {
  
  if( loading === true )
    return (
      <div data-testid="loading"  className="rounded-full h-5 w-5  bg-yellow-400 animate-pulse scale-up-center"></div>
    )

  return  null 
}


export default Loading