import { gql } from '@apollo/client';

const TEST_QUERY = gql`
  query queryTest {
    test
  }
`;

const LIST_FIELDS = gql`
  fragment ListFields on List {
    userId
    id
    description
    currency
    updatedAt
    createdAt
    user {
          userId
          name
        }
  }
`;

const ITEM_FIELDS = gql`
  fragment ItemFields on Item {
    id,
    userId,
    listId
    name,
    description,
    price,
    done,
    default
    createdAt,
    updatedAt
    quantity {
      value,
      unit
    },
      
  }
`;

const GET_LISTS = gql`
  
  ${LIST_FIELDS}
  query getLists {
      lists{
        ...ListFields
        sharedWith {
          id,
           userId,
           name,
           email,
           createddAt
         }
      }
    }
`;

const GET_LISTS_SHARED = gql`
  ${LIST_FIELDS}
  query listShared {
      lists : listsShared  {
        ...ListFields
      }
    }
`;

const GET_LIST_ById = gql`
  ${LIST_FIELDS}
  ${ITEM_FIELDS}
  query getListById($listId : String) {
      list : listById (listId : $listId ) {
        ...ListFields
        sharedWith {
          id,
           userId,
           name,
           email,
           createddAt
         },
        items {
          ...ItemFields
          user {
            userId,
            name
          }
          
        }

      }
  }


`;

const GET_DEFAULT_ITEMS = gql`
  ${ITEM_FIELDS}
  query getItemsDefault {
    itemsDefault {
      ...ItemFields
    }
  }


`;

const GET_USER_ById = gql`
  query userBydId($userId :  String) {
    userById(userId : $userId) {
      userId,
      name,
      email
    }
  }
`;

const SEARCH_USER = gql`
  query searchUser($searchKey :  String) {
    searchUser(searchKey : $searchKey) {
      userId,
      name,
      email
    }
  }
`;



export {
  TEST_QUERY,
  GET_LISTS,
  GET_LIST_ById,
  GET_DEFAULT_ITEMS,
  GET_LISTS_SHARED,
  GET_USER_ById,
  SEARCH_USER
}
