

const CopyRight : React.FC = () => {

  return (
    <div>
      <p className="text-gray-200 text-center font-mono text-sm">Copyright 2021. All rights reserved </p>
    </div>
  )
}

export default CopyRight
