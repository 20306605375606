
import AppRouters from './components/AppRouters'
import LandingPage from './pages/LandingPage'

import { isloggedInService } from './services/loginServices'


function App() {

  if( isloggedInService() )
    return <AppRouters />;

  else
    return <LandingPage />;
    
}

export default App;
