import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { ItemModel, ListModel, ListItemModel, QuantityModel } from '../../models/ListModel'
import { AddItemResponse, ListResponse } from '../../models/Response'

import Loading from '../common/Loading'
import ShowError from '../common/ShowError'
import { ADD_ITEM } from '../../graphql/mutations'
import { GET_LIST_ById } from '../../graphql/querys'


type Iprops = {
  listId : string
}

const AddNewItem : React.FC<Iprops> = ( { listId } ) => {


  const model : ItemModel =
  {description : "",done: false, name : "", price : 0, priority : "",
  quantity : {  },  userId : "", listId, default :  false }

  const [showMoreFields, setshowMoreFields]  = useState(false)
  const [newItemModel, setNewItemModel]      = useState<ItemModel>(model)
  const [newQuantity, setNewQuantity]        = useState<QuantityModel>( { unit : "", value : 0 } )

  const [addItem, { loading, error }]        = useMutation<AddItemResponse>(ADD_ITEM,
    {variables: {  } , update: (cache, { data : _newData }) => {
    try {

      let _data = cache.readQuery<ListResponse>({ query: GET_LIST_ById,  variables: { listId }  });

      if ( !_data || !_data?.list || !_newData?.addItem)
        return

      let newItems : ListItemModel[] = [_newData!.addItem, ..._data!.list!.items!]
      let  newList : ListModel = { ..._data!.list, items : newItems }

      cache.writeQuery({
        query: GET_LIST_ById,
        data: {
            'list': newList!
        }
      });


    } catch (e) {
      console.log(e)
    }
  }  } );



  if ( error )
    return <div className="flex items-center justify-center"> <ShowError error={ error } /> </div>  


const addItemHandler = () => {

    if ( !newItemModel.name || newItemModel.name.trim().length === 0)
        return

    addItem({ variables: { input: { ...newItemModel, quantity : newQuantity } } })

    setNewItemModel( model )
    setNewQuantity( { value : 0, unit : "" } )

}


  return (

    <div className={" bg-gray-800 rounded-xl p-2 sm:p-4 sm:mx-10  " }>
        
      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 p-1 pt-2 gap-2">
            
        <div className="bg-gray-600 col-start-1 rounded-md col-span-2 sm:col-start-2 lg:col-start-2 lg:col-span-4 flex-grow flex justify-between content-center items-center">

          <input        
            type="text"            
            placeholder="Item, Coffee, eggs, bread"            
            autoComplete="off"            
            onChange={(e) => setNewItemModel({ ...newItemModel!, name: e.target.value })}            
            value={newItemModel.name}            
            className="bg-gray-600 flex-grow rounded-md p-2 py-4 text-white placeholder-gray-400 focus:outline-none " />

          <button
            onClick={() => setshowMoreFields(!showMoreFields)}>
            {showMoreFields
              ? <i className="fas fa-caret-down text-md text-gray-400 m-3 "></i>
              : <i className="fas fa-caret-right text-md text-gray-400 m-3 "></i> 
            }            
          </button>

        </div>  
      </div>


      <div className={"grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 p-1 pt-2 grid-rows-2 gap-2 " + (showMoreFields ? "" : "hidden")} >
          
        <div className="col-start-1 sm:col-start-2 grid grid-cols-2 lg:col-start-2 lg:col-span-2 divide-x-2 divide-gray-500 divide-opacity-20">

          <input
            type="number"
            placeholder="Qty."
            onChange={(e) => setNewQuantity({ ...newQuantity!, value: parseFloat(e.target.value) })}
            value={newQuantity.value || ""}
            className="bg-gray-600 border-0 focus:outline-none p-2 m-0  py-4 rounded-none rounded-l text-white placeholder-gray-300" />


          <input
            type="text"
            placeholder="Unt."
            onChange={ (e )=>  setNewQuantity({ ...newQuantity!, unit: e.target.value  }) }
            value={ newQuantity.unit || "" }
            className="bg-gray-600 border-0 focus:outline-none p-2 m-0 py-4 rounded-none rounded-r text-white placeholder-gray-300"/>
  
        </div>

          <input
            type="number"
            placeholder="Price"
            onChange={ (e )=> setNewItemModel({ ...newItemModel!, price: parseFloat( e.target.value) }) }
            value={newItemModel.price || ""}
            className="bg-gray-600 focus:outline-none  lg:col-span-2 rounded-md p-2 py-4 text-white placeholder-gray-300" />

          
        <input
          type="text"
          placeholder="Note"
          onChange={(e) => setNewItemModel({ ...newItemModel!, description: e.target.value })}
          value={newItemModel.description}
          className="bg-gray-600 col-start-1 sm:col-start-2 col-span-2 lg:col-start-2 lg:col-span-4 rounded-md p-2 py-4 text-white placeholder-gray-300 focus:outline-none "/>

        
        <div className="col-start-1 sm:col-start-2 col-span-2 lg:col-start-2 lg:col-span-4 text-center flex justify-center items-center">

        
          <button
            className="text-gray-300 font-semibold"
            onClick={ ()=> setNewItemModel({ ...newItemModel!, default: !newItemModel.default  }) }>
            
            {newItemModel.default
              ? <i className="fas fa-check-circle fa-lg text-yellow-500 mr-3 py-4"></i> 
              : <i className="far fa-circle fa-lg text-gray-500 mr-3 py-4"></i>
            }
            Add default to new Lists
          </button>
    
        </div>

      </div>

      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 p-1 pt-2 gap-2">         
      
        <button
          onClick={ addItemHandler }
          className="bg-pink-800 col-start-1 sm:col-start-2 lg:col-start-2 lg:col-span-4 col-span-2 flex-grow text-white p-3.5 font-semibold rounded-lg">
              Add Item
          </button>

      </div>

      <div className="flex items-center justify-center my-2">
        <Loading loading={ loading } />
      </div>


    </div>

  )

}

export default AddNewItem
