import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useHistory } from 'react-router'


import { ADD_LIST } from '../../graphql/mutations';
import { AddListResponse } from '../../models/Response';
import { addLisService } from '../../services/apolloServices';
import Loading from '../common/Loading';
import ShowError from '../common/ShowError';

const AddNewList: React.FC = () => {

  let history = useHistory();

  const [newList, setNewList] = useState("")

  const [addList, {  loading, error }] =
  useMutation<AddListResponse>(ADD_LIST, {
    variables: { description: newList }, update: (cache, { data: _newData }) =>
    {
    
      if (!_newData?.list)
      return

      addLisService( cache, _newData!.list )

      const id = _newData?.list.id || ""
  
      if (!id)
          return

      const url = `/list/${ id }`
      history.push( url );

  }  } );


  const addListService = ()=> {

    if (!newList)
      return

     addList()
     setNewList("")

  }


  if ( error )
    return (<div className="flex items-center justify-center">
        <ShowError error={ error } /> </div>
  )

  return (
    <div >

      <div className=" grid grid-cols-2 sm:grid-cols-4 p-1 pt-2 gap-2 ">

        <input
          type="text"
          placeholder="List Name"
          autoComplete="off"
          className={"bg-gray-600 col-start-1 sm:col-start-2 col-span-2 flex-grow rounded-md p-2 py-4 text-white placeholder-gray-300 focus:outline-none "}
          value={newList}
          onChange={(e) => setNewList(e.target.value)}/>

      </div>


      <div className="grid grid-cols-2 sm:grid-cols-4 p-1 pt-2 gap-2">

        <button
          disabled= { loading }
          onClick={addListService}
          className="bg-pink-800 col-start-1 sm:col-start-2 col-span-2 flex-grow text-white p-3.5 font-semibold rounded-lg ">
          New List
        </button>

      </div>

      <div className="flex items-center justify-center my-2">
        <Loading loading={loading} />
      </div>

    </div>
  )
}



export default AddNewList
