import { firebaseConfig, providerGoogle } from '../config/config'

import { UserResponFirebaseAuth, UserModel } from '../models/UserModel'

const isloggedInService = (): boolean => {

  const token = localStorage.getItem('token') || ""
  return token ? true : false

}

const getTokenService = (): string => {

  const token = localStorage.getItem('token') || ""
  return token

}

const getUserLoggedInService = (): UserModel | null => {

  const token = getTokenService()

  if (!token)
    return null

  const base64Url = token.split('.')[1];

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  if (!jsonPayload)
    return null

  type tokenDecodedModel = {
    data: UserModel
    exp: number
    iat: number

  }

  const tokenDecoded: tokenDecodedModel = JSON.parse(jsonPayload)

  const user: UserModel = tokenDecoded.data


  return user

}


const logOutService = () => {

  localStorage.removeItem('token')

  firebaseConfig.auth().signOut()

}


const signInWithGoogle = async () => {

  try {
    const resp = await firebaseConfig.auth().signInWithPopup(providerGoogle)

    let userReponse: UserResponFirebaseAuth

    const isNewUser = resp.additionalUserInfo!.isNewUser

    const token = await resp.user?.getIdToken()

    userReponse = { isNewUser, token: token! }

    return userReponse

  } catch (err) {
    console.log(err)
  }


}

export { isloggedInService, signInWithGoogle, logOutService, getTokenService, getUserLoggedInService }
