import React from 'react'
import { ApolloError } from '@apollo/client'


type IProps = {
  error : ApolloError
}

const ShowError: React.FC<IProps> = ( { error } ) => {
  

   return (

    <div className="bg-red-50 rounded-lg p-2 ">
      
      <h3 className="text-red-900 font-semibold text-xl">Something went wrong!</h3>
      <p className="text-red-800 font-medium"> { error.message } </p>


    </div>
  )
}

export default ShowError