import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { URL_GRAPHQL } from '../config/config';
import { getTokenService } from '../services/loginServices'

const httpLink = createHttpLink({
  uri: URL_GRAPHQL,
});

const authLink = setContext((_, { headers }) => {

  const token = getTokenService()
  
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


export default client
