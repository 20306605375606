import { ApolloCache } from '@apollo/client'

import { GET_LIST_ById, GET_DEFAULT_ITEMS, GET_LISTS } from '../graphql/querys'
import { ListItemModel, ListModel, SharedWith } from '../models/ListModel'
import { ListResponse, ItemsDefaultResponse, ListsResponse } from '../models/Response'
import { UserModel } from '../models/UserModel'

const removeItemFromCaheService = (cache : ApolloCache<any>, listId : string, itemId : string, isDefaultItem? : boolean ) => {

  try {

    if ( isDefaultItem === true )
      _removeItemFromItemsDefault(cache, itemId)
    else
      _removeItemFromList(cache, listId, itemId)

  } catch (e) {
    console.log(e)
  }
}


const toggleItemService = ( cache : ApolloCache<any>, listId : string, itemId : string ) => {
  try {

    let _data = cache.readQuery<ListResponse>({ query: GET_LIST_ById,  variables: { listId }  });

    if ( !_data || !_data?.list )
      return

    let itemUpdated = _data!.list!.items!.find( item => item.id === itemId )

    if ( !itemUpdated )
        return

      itemUpdated = { ...itemUpdated, done : !itemUpdated.done }

    let itemsSelected : ListItemModel[] = _data!.list!.items!.filter((item)=> {
      if (item.id !== itemId && item.done)
        return item
      else
        return false

    })

    let itemsUnselected =_data!.list!.items!.filter((item)=> {
      if (item.id !== itemId && item.done === false)
        return item
      else
        return false

    })

    let  newList : ListModel = { ..._data!.list, items : [...itemsUnselected, itemUpdated! ,  ...itemsSelected,] }
    cache.writeQuery({ query: GET_LIST_ById, data: { 'list': newList! } });


  } catch (e) {
    console.log(e)
  }
}


const shareListWithUserService = ( cache : ApolloCache<any>, listId : string, user : UserModel ) => {
  try {

    let _data = cache.readQuery<ListResponse>({ query: GET_LIST_ById,  variables: { listId }  });

    if ( !_data || !_data?.list )
      return

    const { email, name, userId } = user
    
    let _newUser : SharedWith = { userId, name , email , createdAt : ""}
    
    let  newList : ListModel = { ..._data!.list, sharedWith : [..._data?.list.sharedWith, _newUser! ] }
    cache.writeQuery({ query: GET_LIST_ById, data: { 'list': newList! } });


  } catch (e) {
    console.log(e)
  }
}

const deleteshareListWithUserService = (cache: ApolloCache<any>, listId: string, userId : string) => {
  try {

    let _data = cache.readQuery<ListResponse>({ query: GET_LIST_ById,  variables: { listId }  });

    if ( !_data || !_data?.list )
      return

    
    let sharedWith : SharedWith[] = _data.list.sharedWith.filter( s=> s.userId !== userId )
    
    let  newList : ListModel = { ..._data!.list, sharedWith : [ ...sharedWith ] }
    cache.writeQuery({ query: GET_LIST_ById, data: { 'list': newList! } });


  } catch (e) {
    console.log(e)
  }
}

const addLisService = (cache: ApolloCache<any>, list :  ListModel) => {
 
  try {
    let data = cache.readQuery<ListsResponse>({ query: GET_LISTS });
    let  newList : ListModel[] = []
    let newItemList : ListModel = list

    if (data && data.lists) {
      newList = [newItemList,  ...data?.lists]

    } else {
      data?.lists?.push(newItemList)
    }
    cache.writeQuery({  query: GET_LISTS, data: { 'lists': newList } });

  } catch (e) {
    console.log(e)
  }

}


const updateLisNameService = (cache: ApolloCache<any>, listId: string, newTitle: string) => {
  try {
    let data = cache.readQuery<ListsResponse>({ query: GET_LISTS });
    let  newList : ListModel[];

    if( !data || !data.lists)
      return

    newList = data!.lists.map((list)=> {
      if (list.id === listId) {
        return { ...list, description : newTitle }
      }
      return list
    })


    cache.writeQuery({
      query: GET_LISTS,
      data: {
          'lists': newList
      }
    });

  } catch (e) {
    console.log(e)
  }
}


const updateListCurrencyService = (cache: ApolloCache<any>, listId: string, currency: string) => {
  try {
    let data = cache.readQuery<ListResponse>({ query: GET_LIST_ById,  variables: { listId }  });

    if( !data || !data?.list)
      return

    let  newList : ListModel = { ...data!.list, currency  }
    cache.writeQuery({ query: GET_LIST_ById, data: { 'list': newList! } });

  } catch (e) {
    console.log(e)
  }
}




const removeListFromCacheService = (cache: ApolloCache<any>, listId: string) => {
    try {
      let data = cache.readQuery<ListsResponse>({ query: GET_LISTS });
      let  newList : ListModel[] = []

      if ( !data || !data?.lists ) {
        window.location.replace('/')
        return
      }

      newList = data?.lists.filter( list => list.id !== listId )

      cache.writeQuery({
        query: GET_LISTS,
        data: {
            'lists': newList
        }
      });

      window.location.replace('/')


    } catch (e) {
      console.log(e)
    }
}

const _removeItemFromItemsDefault = ( cache : ApolloCache<any>, itemId : string ) => {

  let _data = cache.readQuery<ItemsDefaultResponse>({ query: GET_DEFAULT_ITEMS});
  if ( !_data || !_data?.itemsDefault )
  return

  let itemsDefault : ListItemModel[] = _data!.itemsDefault!.filter( item => item.id !== itemId)

  cache.writeQuery( { query: GET_DEFAULT_ITEMS, data: { 'itemsDefault': itemsDefault! }  });

}

const _removeItemFromList = ( cache : ApolloCache<any>,listId : string, itemId : string ) => {

  let _data = cache.readQuery<ListResponse>({ query: GET_LIST_ById,  variables: { listId }  });

  if ( !_data || !_data?.list )
  return

  let newItems : ListItemModel[] = _data!.list!.items!.filter( item => item.id !== itemId)
  let  newList : ListModel = { ..._data!.list, items : newItems }

  cache.writeQuery( { query: GET_LIST_ById, data: { 'list': newList! } } );
}

export {
  addLisService,
  removeItemFromCaheService,
  removeListFromCacheService,
  toggleItemService,
  shareListWithUserService,
  deleteshareListWithUserService,
  updateLisNameService,
  updateListCurrencyService
}
