import { useState } from 'react'
import dayjs  from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'


import { ListItemModel } from '../../models/ListModel'
import { isUserOwner } from '../../services/preferenceServices'
import ItemActions from '../List/ItemActions'
import ItemDetail from './ItemDetails'
import { getDateFromUnix } from '../../services/utils'

dayjs.extend(relativeTime)

type IProps = {
  edit            : boolean
  item            : ListItemModel
  isDefaultItem?  : boolean 
  currency?       : string
}

const Item: React.FC<IProps> = ({ edit, item, isDefaultItem, currency = "" }) => {

  const [showDetails, setShowDetails] = useState(false)
  const isOwner                       = isUserOwner(item.userId )
  const userName                      = item.user?.name || ""


  return (
    <li>

      <div className={"flex items-center mt-1 "} >

        <ItemActions edit={ edit } item={ item } isDefaultItem={ isDefaultItem } />

          <div className="flex-grow" onClick={ ()=> setShowDetails( !showDetails ) } >

            <div className="flex justify-between content-center items-center">

              
            <div className="flex content-center items-center">
              
              <h3 className="text-lg sm:text-xl lg:text-2xl font-extrabold">
                {item.name}
              </h3>
              
              <span className="text-gray-400 text-sm font-semibold  align-middle mx-2">
                {item.quantity?.value ? `(${item.quantity?.value}${item.quantity?.unit || ""})` : ""}
              </span>
            
            </div>
            
            <h5 className="text-sm font-bold text-gray-400"> {(item.price) ? currency + item.price : ""} </h5>
            
          </div>
            
          <p className="text-gray-400">
            {item.description}
          </p>
          {isDefaultItem && (
            <p className=" text-gray-400 truncate">  
              {dayjs(getDateFromUnix(item.createdAt)).fromNow()}
            </p>
            
          ) }
          
          {( !isOwner && userName) && (
          
            <h4 className="text-sm text-gray-500 truncate font-semibold " >
              By: <span className="text-gray-400 font-bold ">{ userName }</span>
            </h4>
            
          )  }

        </div>

        <button onClick={ ()=> setShowDetails( !showDetails ) } className="mx-1 p-1">
          { showDetails ? (
            <i className="fas fa-caret-down fa-lg text-gray-500 "></i> 

          ) : (
            <i className="fas fa-caret-right fa-lg text-gray-500 "></i>
          ) }
          </button>

        </div>

        { (showDetails ? <ItemDetail item={ item } setShowDetails = { setShowDetails } isDefaultItem ={ isDefaultItem } /> : null ) }


    </li>
  )
}


export default Item
