
import { useHistory } from 'react-router-dom'

const useGoBack = ()=> {

  let history   =  useHistory();

  const goBack = () => {
    
      history.push('/')
  }

  return goBack

}


export default useGoBack
