import React from 'react'
import { useMutation } from '@apollo/client'

import { DELETE_ITEM, TOGGLE_ITEM } from '../../graphql/mutations'
import Loading from '../common/Loading'
import { ListItemModel } from '../../models/ListModel'

import { removeItemFromCaheService, toggleItemService } from '../../services/apolloServices'

type IProps = {
  edit            : boolean
  item            : ListItemModel
  isDefaultItem?  : boolean
}
 

const ItemActions : React.FC<IProps> = ( { edit, item, isDefaultItem } )=> {

  const { listId, id : itemId }  = item

  const [deleteItem, { loading }]   = useMutation(DELETE_ITEM, {variables:  { input : { listId , itemId, isDefaultItem}  }
    , update: cache =>  removeItemFromCaheService( cache, listId, itemId, isDefaultItem )  } );

  const [toggleItem, { loading : loadingToggle }]   = useMutation(TOGGLE_ITEM,
    {variables: { ToogleItemInput : { listId, id: itemId, done : !item.done } } , update: cache => toggleItemService( cache, listId, itemId)      } );


if( loading || loadingToggle )
  return <Loading loading={ loading  || loadingToggle } />

if ( edit )
return   (
    <button onClick={ ()=> deleteItem() } >
      <i className="fas fa-trash fa-lg text-red-500 mr-3 font-bold"></i>
    </button>
  )

if ( item.done )
  return   (
    <button
      onClick={ ()=> toggleItem() }>
      <i className="fas fa-check-circle fa-lg text-yellow-500 mr-3"></i>
    </button>
    )

  return (
    <button
      onClick={ ()=> toggleItem() }>
      <i className="far fa-circle fa-lg text-gray-500 mr-3"></i>
    </button>
  )
}

export default ItemActions
